import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomePage')
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "about" */ '../views/Events')
  },
  {
    path: '/site/:slug?',
    name: 'site',
    component: () => import('../views/CustomSite/QuickCharge.vue'),
  },
  {
    path: '/site/:slug/wallet',
    name: 'site-wallet',
    component: () => import('../views/CustomSite/QuickChargerWallet.vue'),
  },
  {
    path: '/site/:slug/billing',
    name: 'site-billing',
    component: () => import('../views/CustomSite/BillingForm.vue'),
  },
  {
    path: '/site/:slug/account',
    name: 'account-login',
    component: () => import('../views/CustomSite/QuickChargeLogin.vue'),
  },
  {
    path: '/site/:slug/account/wallet',
    name: 'account-wallet',
    component: () => import('../views/CustomSite/QuickChargerWallet.vue'),
  },
  {
    path: '/site/:slug/account/register',
    name: 'account-register',
    component: () => import('../views/CustomSite/QuickChargeRegister.vue'),
  },
  {
    path: '/site/:slug/account/reset-password/:resetPasswordToken',
    name: 'reset-password',
    component: () => import('../views/CustomSite/PasswordReset.vue'),
  },
  {
    path: '/site/:slug/account/your-account',
    name: 'your-account',
    component: () => import('../views/CustomSite/YourAccount.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsView')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy')
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/Cookies')
  },
  {
    path: '/privacy-app',
    name: 'privacy-app',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyApp')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView')
  },
  {
    path: '/need-help',
    name: 'need-help',
    component: () => import(/* webpackChunkName: "about" */ '../views/NeedHelp')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqView')
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import(/* webpackChunkName: "about" */ '../views/Account/CreateAccount')
  },
  {
    path: '/activate',
    name: 'activate',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account/ActivateAccount'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
  {
    path: '/unsub',
    name: 'unsub',
    component: () => import(/* webpackChunkName: "subinscricao" */ '../views/unsub.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
})
// Redirect le-mans to algarve-classic-fetival
router.beforeEach((to, from, next) => {
  if (to.params.slug === 'festival-ticket-zone') {
    next({ name: 'site', params: { slug: 'party-fest' } });
  } else {
    next();
  }
});

export default router
